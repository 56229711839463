import React, { useState, useEffect } from 'react';
import './Main.scss';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactGA from 'react-ga';
import './Footer.scss';
import CookieConsent, { Cookies } from "react-cookie-consent";

const Footer: React.FC = () => {

    useEffect(() => {
        ReactGA.pageview(document.location.pathname + document.location.search);
    }, []);

    const [modalShow, setModalShow] = useState(false);
    const [modalCookieShow, setModalCookieShow] = useState(false);
    const [modalLegalNoticeShow, setModalLegalNotice] = useState(false);

    const handleClose = () => {
        ReactGA.pageview(document.location.pathname);
        setModalShow(false);
    };
    const handleShow = () => {
        ReactGA.pageview(document.location.pathname + "privacy");
        setModalShow(true);
    };

    const handleCookieClose = () => {
        ReactGA.pageview(document.location.pathname);
        setModalCookieShow(false);
    };
    const handleCookieShow = () => {
        ReactGA.pageview(document.location.pathname + "cookie");
        setModalCookieShow(true);
    };

    const handleLegalNoticeClose = () => {
        ReactGA.pageview(document.location.pathname);
        setModalLegalNotice(false);
    };
    const handleLegalNoticeShow = () => {
        ReactGA.pageview(document.location.pathname + "legalnotice");
        setModalLegalNotice(true);
    };


    return (
        <div className="footer">
            <button className="like-anchor" onClick={handleLegalNoticeShow} >MENTIONS LÉGALES</button>
            <span> - </span>
            <button className="like-anchor" onClick={handleShow}>CONFIDENTIALITÉ</button>
            <span> - </span>
            <button className="like-anchor" onClick={handleCookieShow}>COOKIES</button>

            <PopupPrivacy show={modalShow} onHide={handleClose} />
            <PopupCookiePolicy show={modalCookieShow} onHide={handleCookieClose} />
            <PopupLegalNotice show={modalLegalNoticeShow} onHide={handleLegalNoticeClose} />

            <CookieConsent
                location="top"
                buttonText="D'ACCORD"
                cookieName="policyCookie"
                style={{ background: "rgba(43, 55, 59, .6)" }}
                contentStyle={{ flex: "none !important" }}
                buttonStyle={{ backgroundColor: "white", color: "black", fontSize: "13px", margin : "10px" }}
                expires={150}>
                Nous utilisons des cookies pour personnaliser le contenu et analyser notre trafic.{" "}
                <button className="like-anchor underline" onClick={handleCookieShow}>En savoir plus</button>
            </CookieConsent>
        </div>
    );

    function PopupPrivacy(props: any) {

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered className="fade" animation={true} scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        POLITIQUE DE CONFIDENTIALITÉ
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p>Chez chris-crea-coiffure.com, accessible depuis https://chris-crea-coiffure.com, l'une de nos principales priorités est la protection de la vie privée de nos visiteurs. Ce document sur la politique de confidentialité contient des types d'informations qui sont collectées et enregistrées par chris-crea-coiffure.com et la façon dont nous les utilisons.</p>
                    <p>Si vous avez d'autres questions ou si vous souhaitez obtenir plus d'informations sur notre politique de confidentialité, n'hésitez pas à nous contacter.</p>
                    <p>Cette politique de confidentialité s'applique uniquement à nos activités en ligne et est valable pour les visiteurs de notre site web en ce qui concerne les informations qu'ils ont partagées et/ou collectées dans chris-crea-coiffure.com. Cette politique n'est pas applicable aux informations collectées hors ligne ou par des canaux autres que ce site web.</p>

                    <h2>Consentement</h2>

                    <p>En utilisant notre site Web, vous acceptez par la présente notre politique de confidentialité et vous acceptez ses conditions.</p>

                    <h2>Collecte de données</h2>

                    <p>Les informations personnelles que l'on vous demande de fournir, et les raisons pour lesquelles on vous demande de les fournir, vous seront clairement indiquées au moment où nous vous demanderons de fournir vos informations personnelles.</p>
                    <p>Si vous nous contactez directement, nous pouvons recevoir des informations supplémentaires vous concernant telles que votre nom, votre adresse électronique, votre numéro de téléphone, le contenu du message et/ou des pièces jointes que vous pouvez nous envoyer, ainsi que toute autre information que vous choisissez de fournir.</p>
                    <p>Lorsque vous vous inscrivez pour un compte, nous pouvons vous demander vos coordonnées, y compris des éléments tels que votre nom, le nom de votre société, votre adresse, votre adresse électronique et votre numéro de téléphone.</p>

                    <h2>Comment nous utilisons vos informations</h2>

                    <p>Nous utilisons les informations que nous recueillons de différentes manières, notamment pour</p>

                    <ul>
                        <li>Fournir, exploiter et entretenir notre site web</li>
                        <li>Améliorer, personnaliser et développer notre site web</li>
                        <li>Comprendre et analyser la façon dont vous utilisez notre site web</li>
                        <li>Développer de nouveaux produits, services, caractéristiques et fonctionnalités</li>
                        <li>Communiquer avec vous, soit directement, soit par l'intermédiaire d'un de nos partenaires, y compris pour le service à la clientèle, pour vous fournir des mises à jour et d'autres informations relatives au site web, ainsi qu'à des fins de marketing et de promotion</li>
                        <li>Envoyer des courriels</li>
                        <li>Rechercher et prévenir la fraude</li>
                    </ul>

                    <h2>Fichiers de logs</h2>

                    <p>chris-crea-coiffure.com suit une procédure standard d'utilisation des fichiers journaux. Ces fichiers enregistrent les visiteurs lorsqu'ils visitent des sites web. Tous les hébergeurs le font et une partie de l'analyse des services d'hébergement. Les informations recueillies par les fichiers journaux comprennent les adresses de protocole internet (IP), le type de navigateur, le fournisseur d'accès internet (FAI), l'horodatage, les pages de renvoi/sortie et éventuellement le nombre de clics. Ces données ne sont pas liées à des informations permettant d'identifier une personne. L'objectif de ces informations est d'analyser les tendances, d'administrer le site, de suivre les mouvements des utilisateurs sur le site et de recueillir des informations démographiques.</p>

                    <h2>Droits de protection des données RGPD</h2>

                    <p>Nous souhaitons nous assurer que vous êtes pleinement conscient de tous vos droits en matière de protection des données. Chaque utilisateur a droit à ce qui suit:</p>
                    <p>Le droit d'accès - Vous avez le droit de demander des copies de vos données personnelles. Nous pouvons vous facturer une petite somme pour ce service.</p>
                    <p>Droit de rectification - Vous avez le droit de nous demander de corriger toute information que vous jugez inexacte. Vous avez également le droit de nous demander de compléter les informations que vous jugez incomplètes.</p>
                    <p>Le droit d'effacement - Vous avez le droit de demander que nous effacions vos données personnelles, sous certaines conditions.</p>
                    <p>Le droit de restreindre le traitement - Vous avez le droit de demander que nous restreignions le traitement de vos données personnelles, sous certaines conditions.</p>
                    <p>Le droit d'opposition au traitement - Vous avez le droit de vous opposer au traitement de vos données personnelles, sous certaines conditions.</p>
                    <p>Le droit à la portabilité des données - Vous avez le droit de demander que nous transférions les données que nous avons collectées à une autre organisation, ou directement à vous, sous certaines conditions.</p>
                    <p>Si vous faites une demande, nous avons un mois pour vous répondre. Si vous souhaitez exercer l'un de ces droits, veuillez nous contacter.</p>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-my-primary" type="submit" form="form1" id="close" onClick={handleClose}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        );
    }


    function PopupCookiePolicy(props: any) {

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered className="fade" animation={true} scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        POLITIQUE DE CONFIDENTIALITÉ DE COOKIES
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Cette politique en matière de cookies (&quot;Politique de confidentialité de cookies&quot;) décrit ce que sont les cookies et comment l'opérateur du site web (&quot;Opérateur du site web&quot;, &quot;nous&quot;) les utilise sur le <a target="_blank" rel="nofollow" href="https://chris-crea-coiffure.com">chris-crea-coiffure.com</a> site web et l'un de ses produits ou services.</p>
                    <p>Vous devez lire cette politique afin de comprendre quel type de cookies nous utilisons, les informations que nous recueillons à l'aide des cookies et la manière dont ces informations sont utilisées. Elle décrit également les choix qui s'offrent à vous pour accepter ou refuser l'utilisation de cookies.</p>
                    <h2>Qu'est-ce que les cookies ?</h2>
                    <p>Les cookies sont de petits morceaux de données stockés dans des fichiers texte qui sont enregistrés sur votre ordinateur ou d'autres appareils lorsque les sites web sont chargés dans un navigateur. Ils sont largement utilisés pour se souvenir de vous et de vos préférences, soit pour une seule visite (grâce à un &quot;cookie de session&quot;) soit pour des visites répétées (grâce à un &quot;cookie persistant&quot;).</p>
                    <p>Les cookies de session sont des cookies temporaires utilisés au cours de votre visite sur le site web, et ils expirent lorsque vous fermez le navigateur web.</p>
                    <p>Les cookies persistants sont utilisés pour mémoriser vos préférences au sein de notre site web et restent sur votre bureau ou votre appareil mobile même après la fermeture de votre navigateur ou le redémarrage de votre ordinateur. Ils vous garantissent une expérience cohérente et efficace lors de votre visite sur notre site web ou de l'utilisation de nos services.</p>
                    <p>Les cookies peuvent être définis par le site web (&quot;cookies tierces&quot;), ou par des tiers, tels que ceux qui servent le contenu ou fournissent des services publicitaires ou analytiques sur le site web (&quot;cookies tierces&quot;). Ces tiers peuvent vous reconnaître lorsque vous visitez notre site web et également lorsque vous visitez certains autres sites web.</p>
                    <h2>Quel type de cookies utilisons-nous ?</h2>
                    <h3>Cookies d'analyse</h3>
                    <p>Ces cookies nous permettent, ainsi qu'à des services tiers, de collecter des données agrégées à des fins statistiques sur la façon dont nos visiteurs utilisent le site web. Ces cookies ne contiennent pas d'informations personnelles telles que les noms et les adresses électroniques et sont utilisés pour nous aider à améliorer votre expérience d'utilisateur du site web.</p>
                    <h2>Quelles sont vos options en matière de cookies ?</h2>
                    <p>Si vous n'aimez pas l'idée des cookies ou de certains types de cookies, vous pouvez modifier les paramètres de votre navigateur pour supprimer les cookies déjà définis et pour ne pas accepter de nouveaux cookies. Pour en savoir plus sur la façon de procéder ou sur les cookies, visitez le site <a target="_blank" href="https://www.internetcookies.org">internetcookies.org</a></p>
                    <h2>Changements et amendements</h2>
                    <p>Nous nous réservons le droit de modifier la présente politique relative au site Web ou aux services à tout moment, avec effet dès la publication d'une version actualisée de cette politique sur le site Web. Dans ce cas, nous réviserons la date de mise à jour figurant au bas de cette page. La poursuite de l'utilisation du site web après de telles modifications constitue votre consentement à ces modifications.</p>
                    <h2>Acceptation de cette politique</h2>
                    <p>Vous reconnaissez avoir lu la présente politique et en accepter toutes les conditions. En utilisant le site web ou ses services, vous acceptez d'être lié par la présente politique. Si vous n'acceptez pas de respecter les conditions de la présente politique, vous n'êtes pas autorisé à utiliser ou à accéder au site web et à ses services.</p>
                    <h2>Nous contacter</h2>
                    <p>Si vous souhaitez nous contacter pour en savoir plus sur cette politique ou pour toute question relative à notre utilisation des cookies, vous pouvez le faire via le formulaire de contact.</p>
                    <p>Ce document a été mis à jour pour la dernière fois le 20 avril 2020</p>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-my-primary" type="submit" form="form1" id="close" onClick={handleCookieClose}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        );
    }


    function PopupLegalNotice(props: any) {

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered className="fade" animation={true} scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        MENTIONS LÉGALES
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>Informations</h2>
                    <p>CHRIS CRÉA COIFFURE <br/>
                    4, Rue de la Poste <br />
                    57320 WALDWEISTROFF <br />
                    FRANCE <br />
                    SIRET (FR) : 44022313900013<br />
                    Contact: info@chris-crea-coiffure.com <br />
                    <br />
                    Ce site internet est développé par Mathieu Perrein.</p>
                    <p>Pour toute question sur la confidentialité, vous pouvez consulter les liens sur ce site en bas de page ou nous contacter via le formulaire de contact.</p>
                    <h2>Hébergement</h2>
                    <p>Ce site web est hébergé par OVH – 2 rue Kellermann – 59100 ROUBAIX – France.</p>
                    <p>Pour contacter cet hébergeur, rendez-vous à l’adresse <a href="http://www.ovh.com/fr/support/">OVH Support.</a></p>
                    <h2>Propriété intellectuelle</h2>
                    <p>Tous les médias et contenus présents sur ce site web, sauf mention contraire, sont à la propriété de CHRIS CRÉA COIFFURE et sont protégés par les droits de propriété intellectuelle.</p>
                    <p>La reproduction ou l’utilisation de tous médias ou des contenus publiés sur ce site sont uniquement autorisées pour un usage strictement personnel et privé en mentionnant la source. </p>
                    <p>Toute reproduction est interdite et sanctionnée pénalement.</p>
                    <h2>Content</h2>
                    <p>Nous sommes très attentifs à la qualité du contenu, de ce fait, les données contenues sur ce site (liens inclus) sont censées être exactes au moment de leur publication, mais peuvent par la suite devenir inexactes ou dépassées. Nous ne garantissons pas l’exactitude ou l’exhaustivité des informations qui y sont proposées. L’utilisateur est également conscient que ces informations sont susceptibles d’être modifiées sans avis préalable. Il en résulte que nous déclinons toute responsabilité quant au contenu de ce site ou à l’utilisation qui pourrait en être faite.</p>
                    <p>Les liens hypertextes pointant vers d'autres sites internet pouvant être présents sur ce site Internet n’engagent pas notre responsabilité quant au contenu de ces sites. La création de liens vers d’autres sites ne signifie pas que nous cautionnons ces sites ou leur contenu. En outre, nous ne pouvons pas être tenus responsables des erreurs dans les adresses de site ou les noms de domaine figurant dans ce site.</p>
                    <p>Malgré les efforts consentis pour éviter autant que possible toute interruption due à des problèmes techniques, nous déclinons toute responsabilité du fait d’interruptions de services ou d’autres problèmes techniques.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-my-primary" type="submit" form="form1" id="close" onClick={handleLegalNoticeClose}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Footer;