import React from 'react';
import { Main } from './components/layout'
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ReactGA from 'react-ga';
import './index.css';

function App() {

    ReactGA.initialize('UA-164064720-1');
    
    return (
        <BrowserRouter>
            <div className="App">
            <Route path="/" exact component={Main} />
            </div>
            
       </BrowserRouter>
    );
}

export default App;